import * as React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { textToParagraphs } from '../../utils/strings';
import { getNeonShadow } from '../Button';
import { PodcastPlayerContext } from './PodcastPlayer';
import theme from '../../styles/theme';
import { PlayIcon } from './podcastIcons';
import { medias } from '../../styles/helpers/mediaQueries';
import { Chevron } from '../Slack/slackIcons';

export interface IPodcastPreviewData {
  isoDate: string;
  title: string;
  contentSnippet: string;
  enclosure: { url: string };
  canExpand?: boolean;
}

const Wrapper = styled.div`
  background: ${theme.colors.darkPurple};
  padding: 1rem;
  box-shadow: ${getNeonShadow(theme.colors.primaryLight)};
  display: flex;
  margin: 5rem auto;
  border-radius: 0.5rem;
`;

const ImageWrapper = styled.div`
  flex: 1 0 50px;
  max-width: 180px;
  margin-right: 1.25rem;
  @media ${medias.phoneMax} {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  flex: 3;
  position: relative;
  padding-bottom: 1.5rem;
`;

const Title = styled.h3`
  font-size: 1rem;
  margin: 0.35rem 0 0.75rem;
`;

const StyledDate = styled.span`
  font-size: 0.8rem;
  /* margin-left: 1rem; */
  flex: 1;
  text-align: left;
  font-weight: 300;
  white-space: nowrap;
`;

const Excerpt = styled.div`
  font-size: 0.8em;
  line-height: 1.65;
  overflow: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? 'auto' : 'hidden'};
  height: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? '200px' : '80px'};
  border-bottom: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? `1px solid ${theme.colors.primary}` : '1px solid transparent'};
  transition: ${theme.transitions.slow} all,
    ${theme.transitions.slow} 0.4s border-bottom;
  scroll-behavior: smooth;
  p:first-of-type {
    margin-top: 0;
  }
`;

const PlayCircle = styled.span`
  background: white;
  box-shadow: ${getNeonShadow('#fff')};
  border-radius: 50%;
  font-size: 3rem;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  svg {
    fill: ${theme.colors.darkPurple};
    width: 40%;
    transform: translateX(10%);
  }
`;

const PlayButton = styled.button`
  color: white;
  position: absolute;
  bottom: -1.5rem;
  right: -1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  transition: ${theme.transitions.default} filter;
  :focus,
  :hover {
    outline: none;
    filter: brightness(0.7);
  }
`;

const ExpandButton = styled.button`
  color: ${theme.colors.primaryLight};
  transition: ${theme.transitions.default};
  outline: none;
  font-size: 0.8em;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 0;
  :focus,
  :hover {
    color: white;
    svg {
      color: white;
    }
  }
  svg {
    width: 1em;
    fill: currentColor;
    margin-right: 0.5em;
    transition: ${theme.transitions.slow} 0.2s transform;
    transform: ${({ isExpanded }: { isExpanded: boolean }) =>
      isExpanded ? 'rotate(180deg)' : 'rotate(0)'};
  }
`;

export class PodcastPreview extends React.Component<
  IPodcastPreviewData,
  { isExpanded: boolean }
> {
  excerptRef: React.RefObject<HTMLDivElement>;

  constructor(props: IPodcastPreviewData) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this.excerptRef = React.createRef();
  }

  toggleExpansion = () => {
    if (this.excerptRef.current) {
      this.excerptRef.current.scrollTop = 0;
    }
    this.setState(prev => ({ isExpanded: !prev.isExpanded }));
  };

  render() {
    const { props, state } = this;
    const date = new Date(Date.parse(props.isoDate));
    return (
      <Wrapper property="itemListElement" typeof="Episode">
        <meta property="isAccessibleForFree" content="true" />
        <meta property="datePublished" content={props.isoDate} />
        <meta property="encodingFormat" content="audio/mpeg" />
        <meta property="inLanguage" content="Portuguese" />
        <meta property="headline" content={props.title} />
        <span property="audio">
          <meta property="contentUrl" content={props.enclosure.url} />
          <meta property="encodingFormat" content="audio/mpeg" />
          <meta property="uploadDate" content={props.isoDate} />
        </span>
        <ImageWrapper>
          <StaticQuery
            query={graphql`
              query podcastImageQuery {
                file(relativePath: { eq: "podcast-image.png" }) {
                  childImageSharp {
                    fluid(maxWidth: 180, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                    original {
                      src
                    }
                  }
                }
              }
            `}
            render={({ file }: any) => (
              <>
                <GatsbyImage
                  fluid={file.childImageSharp.fluid}
                  alt="Imagem em destaque do podcast"
                />
                <span property="thumbnailUrl" typeof="URL">
                  <meta
                    property="url"
                    content={file.childImageSharp.original.src}
                  />
                </span>
              </>
            )}
          />
        </ImageWrapper>
        <ContentWrapper>
          {/* <TitleWrapper> */}
          <StyledDate>
            {date.toLocaleDateString('pt', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </StyledDate>
          <Title property="name">{props.title}</Title>
          {/* </TitleWrapper> */}
          <Excerpt isExpanded={state.isExpanded} ref={this.excerptRef}>
            {props.canExpand
              ? textToParagraphs(props.contentSnippet)
              : `${props.contentSnippet.substr(0, 200)}...`}
          </Excerpt>
          {props.canExpand ? (
            <ExpandButton
              isExpanded={state.isExpanded}
              onClick={this.toggleExpansion}
            >
              <Chevron />
              {state.isExpanded ? 'Ver menos' : 'Ver mais'}
            </ExpandButton>
          ) : null}
          <PodcastPlayerContext.Consumer>
            {({ changeCurrentPodcast }) => (
              <PlayButton
                onClick={() =>
                  changeCurrentPodcast({
                    url: props.enclosure.url,
                    title: props.title,
                    date: props.isoDate,
                  })
                }
              >
                Ouvir agora
                <PlayCircle>
                  <PlayIcon />
                </PlayCircle>
              </PlayButton>
            )}
          </PodcastPlayerContext.Consumer>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default PodcastPreview;
