import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Chevron } from '../Slack/slackIcons';
import { getNeonShadow } from '../Button';
import theme from '../../styles/theme';
import { medias } from '../../styles/helpers/mediaQueries';

export interface IPaginationProps {
  skip: number;
  limit: number;
  length: number;
  basePath: string;
}

const Wrapper = styled.aside`
  display: flex;
  max-width: calc(400px + 5vw);
  margin: 2rem auto;
  padding: 0 1rem 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  flex: 1;
  outline: none;
  @media ${medias.phoneMax} {
    font-size: 0.9rem;
  }
  :hover,
  :focus {
    div {
      transform: scale(1.05);
      box-shadow: ${getNeonShadow(theme.colors.primaryLight)};
    }
  }
  &.prev {
    div {
      margin-right: 1rem;
    }
    svg {
      transform: rotate(90deg);
    }
  }
  &.next {
    justify-content: flex-end;
    div {
      margin-left: 1rem;
    }
    svg {
      transform: rotate(-90deg);
    }
  }
`;

const CircleChevron = styled.div`
  background: white;
  box-shadow: ${getNeonShadow('#fff')};
  border-radius: 50%;
  font-size: 2em;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${theme.transitions.default};
  svg {
    width: 0.5rem;
  }
`;

export const Pagination: React.SFC<IPaginationProps> = props => {
  const { skip, limit, length, basePath } = props;
  return (
    <Wrapper>
      {skip !== 0 ? (
        <StyledLink
          className="prev"
          to={`/${basePath}${
            skip === limit ? '' : `/pagina-${Math.floor(skip / limit)}`
          }`}
        >
          <CircleChevron>
            <Chevron />
          </CircleChevron>
          Anterior
        </StyledLink>
      ) : null}
      {length > skip + limit ? (
        <StyledLink
          className="next"
          to={`/${basePath}/pagina-${Math.floor(skip / limit) + 2}`}
        >
          Próxima
          <CircleChevron>
            <Chevron />
          </CircleChevron>
        </StyledLink>
      ) : null}
    </Wrapper>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
