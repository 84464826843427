import * as React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Pagination from '../../components/listPages/Pagination';
import { IPodcastPreviewData } from '../../components/Podcast/PodcastPreview';
import PageMeta, { IPageMeta } from '../../components/PageMeta';
import theme from '../../styles/theme';
import { medias } from '../../styles/helpers/mediaQueries';
import BasicLayout from '../../layouts/BasicLayout';
import { TitleWrapper } from '../../components/listPages/reusableComponents';
import LogoWithSubtitle from '../../components/LogoWithSubtitle';
import LogoSpotify from '../../components/icons/LogoSpotify';
import {
  ApplePodcastsLogo,
  GooglePodcastsLogo,
} from '../../components/PodcastIcons';
import PodcastList from '../../components/Podcast/PodcastList';
import SocialLinks from '../../components/SocialLinks';

export interface IPodcastListTemplateProps {
  pageContext: {
    limit: number;
    skip: number;
    allPodcasts: IPodcastPreviewData[];
  };
  data: {
    config: {
      podcast: {
        spotify: string;
        google: string;
        apple: string;
      };
    };
    podcastPage: {
      meta: IPageMeta;
      title: string;
      subtitle: string;
    };
  };
}

const Wrapper = styled.div``;

const LinksBar = styled.section`
  max-width: ${theme.lgContainerWidth};
  margin: 5rem auto;
  padding: 1.25rem 0;
  border: 0 solid rgba(255, 255, 255, 0.3);
  border-width: 1px 0 1px 0;
  a {
    margin: 1.25rem auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    max-width: 200px;
  }
  svg {
    width: 3.25rem;
    margin-right: 0.5rem;
  }
  @media ${medias.tabletMin} {
    padding: 2.25rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    a {
      margin: 0 0.5rem;
      max-width: none;
    }
  }
`;

export const PodcastListTemplate: React.SFC<IPodcastListTemplateProps> = props => {
  const { skip, limit, allPodcasts } = props.pageContext;
  const {
    podcastPage: { title, subtitle, meta },
    config: { podcast },
  } = props.data;
  const podcasts = allPodcasts.slice(skip, skip + limit);
  return (
    <BasicLayout>
      <PageMeta {...meta} title="Podcast" />
      <TitleWrapper>
        <LogoWithSubtitle subtitle="Podcast" />
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </TitleWrapper>
      <LinksBar>
        <a href={podcast.spotify} target="_blank" rel="noopener noreferrer">
          <LogoSpotify /> Spotify
        </a>
        <a href={podcast.apple} target="_blank" rel="noopener noreferrer">
          <ApplePodcastsLogo /> Apple Podcasts
        </a>
        <a href={podcast.google} target="_blank" rel="noopener noreferrer">
          <GooglePodcastsLogo /> Google Podcasts
        </a>
      </LinksBar>
      <Wrapper>
        <PodcastList podcasts={podcasts} isPodcastPage={true} />
      </Wrapper>
      <Pagination
        skip={skip}
        limit={limit}
        length={allPodcasts.length}
        basePath="podcast"
      />
      <SocialLinks />
    </BasicLayout>
  );
};

export default PodcastListTemplate;

export const podcastQuery = graphql`
  query PodcastQuery {
    config: sanityConfig {
      podcast {
        google
        apple
        spotify
      }
    }
    podcastPage: sanityPodcastPage(_id: { eq: "podcastPageId" }) {
      meta {
        seoDescription
        seoTitle
        ogImage {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      title
      subtitle
    }
  }
`;
