import * as React from 'react';
import styled from 'styled-components';

import PodcastPreview, { IPodcastPreviewData } from './PodcastPreview';
import theme from '../../styles/theme';

export interface IPodcastListProps {
  podcasts: IPodcastPreviewData[];
  isPodcastPage?: boolean;
}

const Wrapper = styled.div`
  max-width: ${theme.smContainerWidth};
  margin: 0 auto;
`;

export const PodcastList: React.SFC<IPodcastListProps> = props => {
  const { podcasts } = props;
  return (
    <Wrapper typeof="ItemList">
      {podcasts.map(p => (
        <PodcastPreview
          key={p.isoDate}
          {...p}
          canExpand={props.isPodcastPage}
        />
      ))}
    </Wrapper>
  );
};

export default PodcastList;
